import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
// import LogoTitle from '../../assets/images/logo-s.png'
import Logo from './Logo'
import './index.scss'
import videoBg from '../../assets/videos/video-bg.mp4'
// import Tippy from '@tippyjs/react';



const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const [visible, setVisible] = useState(false);


const toggleButton= () => {
  console.log('toggled!')
  setVisible(!visible)
}

  const nameArray = ['G', 'e', 'o', 'r', 'g', 'e']
  const jobArray = [
    'D',
    'e',
    's',
    'i',
    'g',
    'n',
    ' ',
    'E',
    'n',
    'g',
    'i',
    'n',
    'e',
    'e',
    'r',
    '.',
  ]

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <video autoPlay loop muted playsInline id='video'>
        <source src={videoBg} type='video/mp4' />
      </video>
      <section className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m&nbsp;</span>
            {/* <img
              src={LogoTitle}
              alt="JavaScript Developer Name, Web Developer Name"
            /> */}
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={20}
            />
          </h1>
          <h2>Digital Designer | Front-End Developer | Rich Media Ads Specialist | Wordpress Developer</h2>
          <br />
          <div className='buttonRow'>
          <Link to="/portfolio" className="flat-button">
            VIEW PORTFOLIO
          </Link>
          <Link to="/websites" className="flat-button">
            VIEW WEBSITES
          </Link>
          </div>
          <div className='subtext-container'>
            <p className='subText'>Built using React.js </p>
            <p className=''>Last Updated: 3/21/24 @ 1am </p>
          </div>
        </div>
        <div className="svgArt">
          <Logo />
        </div>
      </section>
      {/* <Loader type="pacman" /> */}
    </>
  )
}
export default Home
