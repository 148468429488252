import sog from '../../assets/images/portfolio/screens/website-sog.jpg'
import ralph from '../../assets/images/portfolio/screens/custom-wreck-it-ralph.jpg'
import ralphVid from '../../assets/videos/wreck-it-ralph2-doodle.mp4'
import strangerThumb from '../../assets/images/portfolio/screens/stranger-things-3.jpg'
import strangerVid from '../../assets/videos/stranger_things3.mp4'
import farCryThumb from '../../assets/images/portfolio/screens/custom-far-cry6.jpg'
import pandaThumb from '../../assets/images/portfolio/screens/panda-hangman.jpg'
import ghostThumb from '../../assets/images/portfolio/screens/theme-ghosts.jpg'
import ghostVid from '../../assets/videos/theme-ghostOfTsushima.mp4'

import iqThumb from '../../assets/images/portfolio/screens/iq-chart.jpg'
import iqVid from '../../assets/videos/iq-content-vid.mp4'

import reformThumb from '../../assets/images/portfolio/screens/reform-showcase.jpg'
import reformIPhone from '../../assets/images/portfolio/screens/reform-iphone13.jpg'


import sonyStore from '../../assets/images/portfolio/screens/sony-store.jpg'

import mag from '../../assets/images/portfolio/screens/mag-thumb.jpg'
import magVid from '../../assets/videos/mag_marquee_vid.mp4'

import tick from '../../assets/images/portfolio/screens/the-tick.jpg'
import tickVid from '../../assets/videos/the-tick-video-wall.mp4'

import re7 from '../../assets/images/portfolio/screens/re7.jpg'
import re7Vid from '../../assets/videos/resident-evil7.mp4'

import farcry4 from '../../assets/images/portfolio/screens/farcry4.jpg'
import farcry4Vid from '../../assets/videos/farcry4-vid.mp4'

import warApes from '../../assets/images/portfolio/screens/warApes-thumb.jpg'
import warApesVid from '../../assets/videos/warApes-vid.mp4'

import l4d from '../../assets/images/portfolio/screens/left4dead-thumb.jpg'
import l4dVid from '../../assets/videos/left4Dead_chamSkinMarquee_crop.mp4'

import kingsman from '../../assets/images/portfolio/screens/kingsman-thumb.jpg'
import kingsmanVid from '../../assets/videos/kingsman-vid.mp4'

import mvc from '../../assets/images/portfolio/screens/mvc-thumb.jpg'
import mvcVid from '../../assets/videos/marvel_vs_capcom-vid.mp4'

import sonic from '../../assets/images/portfolio/screens/sonic.jpg'
import sonicVid from '../../assets/videos/sonic-vid.mp4'

import watchdogs from '../../assets/images/portfolio/screens/theme-watchdogs-thumb.jpg'
import watchdogsVid from '../../assets/videos/theme-watchdogs-vid.mp4'


import dnc from '../../assets/images/portfolio/screens/dnc-thumb.jpg'
import dncVid from '../../assets/videos/dnc-vid.mp4'

import cyberpunk from '../../assets/images/portfolio/screens/theme-cyberpunk-thumb.jpg'
import cyberpunkVid from '../../assets/videos/theme-cyberpunk-vid.mp4'

import infamous from '../../assets/images/portfolio/screens/infamous-thumb.jpg'
import infamousVid from '../../assets/videos/infamous-vid.mp4'

import ignStore from '../../assets/images/portfolio/screens/ign-store-thumb.jpg'
import ignStoreVid from '../../assets/videos/ign-store-ads-vid.mp4'

import bloodsport from '../../assets/images/portfolio/screens/bloodsport-imdb-thumb.jpg'

import demoTool from '../../assets/images/portfolio/screens/ign-demo-tool.jpg'

import rockBandRivals from '../../assets/images/portfolio/screens/rock-band-rivals.jpg'

import bestBuy from '../../assets/images/portfolio/screens/best-buy.jpg'

import wholefoods from '../../assets/images/portfolio/screens/whole-foods.jpg'





// galleryData.js

// ... (import images and videos)

const galleryItems = [
  {
    order: 1,
    title: 'Wreck it Ralph',
    type: 'Rich Media Ads',
    summary: 'Perhaps my all time favorite custom ad. Using a transparent video asset supplied by the client, I created this ad of Ralph "breaking" through the IGN site. He then "breaks" another part of site and shows up in the navigation.',
    techUsed: 'This was created using: HTML, CSS, JavaScript, Photoshop, After Effects, Google Ad Manager',
    imgURL: ralph,
    webURL: '',
    videoURL: ralphVid,
    ribbon: 'Featured'
  },
  {
    order: 3,
    title: 'Stranger Things 3',
    type: 'Rich Media Ads',
    summary: 'The client wanted to do something disruptive and something that has never been done before. This ad hacks into the page&#39;s source code and dynamically glitches the 4 stories underneath the ad.',
    techUsed: 'This was created using: HTML, CSS, JavaScript, After Effects, Google Ad Manager',
    imgURL: strangerThumb,
    webURL: '',
    videoURL: strangerVid,
  },
  {
    order: 2,
    title: 'WBD - Creative Showcase',
    type: 'Web Applications',
    summary: 'I noticed one of the roadblocks for sales/planning was not having a centralized place for test pages.  I took it upon myself to create an full application that was easy for someone without technical knowledge to update the site.',
    techUsed: 'This was created using: HTML, CSS, JavaScript, Vue.js, Tailwind.css, AWS',
    imgURL: reformThumb,
    webURL: 'https://network-ad-product-team-george.s3.amazonaws.com/adtools/portfolio/index.html',
    videoURL: '',
  },
  {
    order: 5,
    title: 'Warner Bros. Discovery - iQ',
    type: 'Web Applications',
    summary: 'I created the UI/UX for this internal insights platform using Figma.  I volunteered to learn React.js so I can make some front-end changes to the application and not have to wait every 2 weeks for "simple" revisions.',
    techUsed: 'This was created using: Figma, React.js, HTML, CSS, Javascript ',
    imgURL: iqThumb,
    webURL: '',
    videoURL: iqVid,
  },
  {
    order: 6,
    title: 'Hangman Game',
    type: 'Web Applications',
    summary: 'As part of an application for an internship at LinkedIn, I was asked to build a hangman type of game from scratch using JavaScript.  I went above and beyond and created a design and added animation once the code was working.',
    techUsed: 'This was created using: HTML, CSS, JavaScript',
    imgURL: pandaThumb,
    webURL: 'https://designingdata.dev/panda-game/',
    videoURL: '',
  },
  {
    order: 7,
    title: 'Far Cry 6',
    type: 'Animated Banner Ads',
    summary: 'R&D Project.  This is an image slider that I created using assets I pulled from the client&#39;s website. ',
    techUsed: 'This was created using: HTML, CSS, JavaScript, Photoshop, Google Ad Manager',
    imgURL: farCryThumb,
    webURL: 'https://designingdata.dev/IGN/far-cry-6/',
    videoURL: '',
  },
  {
    order: 8,
    title: 'Ghost of Tsushima',
    type: 'Full Page Takeovers',
    summary: 'In collaboration with the Product and Engineering team, we created this homepage takeover for this game title. The ad was able to talk to the site and effectively change it through the ad call.',
    techUsed: 'This was created using: HTML, CSS, JavaScript, Photoshop, Google Ad Manager',
    imgURL: ghostThumb,
    webURL: '',
    videoURL: ghostVid,
  },
  {
    order: 9,
    title: 'WBD - Spotlight Unit',
    type: 'Rich Media Ads',
    summary: 'I was able to produtize this full width video known as the "Spotlight Unit" within my first 6 months at Warner Bros. Discovery',
    techUsed: 'This was created using: HTML, CSS, JavaScript, Photoshop, Celtra, Google Ad Manager',
    imgURL: reformIPhone,
    webURL: 'http://turner.celtra.com/preview/b0a45b0d#overrides.deviceInfo.deviceType=Desktop&deviceType=Desktop&placementWidth=1800&placementHeight=auto',
    videoURL: '',
  },
  {
    order: 10,
    title: 'Playstation Store Carousel',
    type: 'Animated Banner Ads',
    summary: 'This is an example of a shoppable ad I created.  I have templatized it to the point where anyone with some technical experience can create their own.',
    techUsed: 'This was created using: HTML, CSS, JavaScript',
    imgURL: sonyStore,
    webURL: 'https://designingdata.dev/IGN/ps-store/',
    videoURL: '',
  },
  {
    order: 11,
    title: 'Mag',
    type: 'Rich Media Ads',
    summary: 'In this unit I synced 3 different ads to start rotating at the same time',
    techUsed: 'This was built using: Flash, Javascript',
    imgURL: mag,
    webURL: '',
    videoURL: magVid
  },
  {
    order: 12,
    title: "Amazon's The Tick",
    type: 'Rich Media Ads',
    summary: 'I created this ad product for IGN.',
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: tick,
    webURL: '',
    videoURL: tickVid
  },
  {
    order: 13,
    title: 'Resident Evil 7',
    type: 'Rich Media Ads',
    summary: 'This ad was created for Capcom&#39;s game.  Working with our motion graphics guru we concepted and created this custom experience.',
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: re7,
    webURL: '',
    videoURL: re7Vid,
  },
  {
    order: 12,
    title: 'Far Cry 4',
    type: 'Rich Media Ads',
    summary: "Probably the most impactful verion of the video wall I created using the client's assets",
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: farcry4,
    webURL: '',
    videoURL: farcry4Vid
  },
  {
    order: 15,
    title: 'War for Planet of the Apes',
    type: 'Rich Media Ads',
    summary: 'Video Wall for Planet of the Apes Movie',
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: warApes,
    webURL: '',
    videoURL: warApesVid
  },
  {
    order: 16,
    title: 'Left 4 Dead',
    type: 'Rich Media Ads',
    summary: 'This product was called the Chamelon Skin where clicking on the character changed different parts of the site as well as played a transparent animation on top of the site.',
    techUsed: 'This was built using: Flash, AE',
    imgURL: l4d,
    webURL: '',
    videoURL: l4dVid
  },
  {
    order: 17,
    title: 'Kingsman',
    type: 'Rich Media Ads',
    summary: 'Video Wall for Kingsman Movie',
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: kingsman,
    webURL: '',
    videoURL: kingsmanVid
  },
  {
    order: 18,
    title: 'Marvel vs. Capcom',
    type: 'Rich Media Ads',
    summary: 'Video Wall for Marvel vs Capcom Game.',
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: mvc,
    webURL: '',
    videoURL: mvcVid
  },
  {
    order: 19,
    title: 'Sonic the Hedgehog',
    type: 'Animated Banner Ads',
    summary: 'This was one of the last prototypes I created for IGN. It is a full width animated ad at the top of the page.',
    techUsed: 'This was built using: HTML, CSS, JavaScript, AE',
    imgURL: sonic,
    webURL: '',
    videoURL: sonicVid
  },
  {
    order: 20,
    title: 'Theme Watchdogs',
    type: 'Full Page Takeovers',
    summary: 'Example of a full page takeover I designed and help develop for the Watchdogs game',
    techUsed: 'This was built using: React, HTML, CSS, JavaScript, AE',
    imgURL: watchdogs,
    webURL: '',
    videoURL: watchdogsVid
  },
  {
    order: 21,
    title: 'Creative Services Sizzle Reel',
    type: 'Other',
    summary: 'Using after effects and some assets created by another designer, I annimated my portion of the animation. Ultimately I connected this to our IGN Homepage (as a test page) and presented it as an ad',
    techUsed: 'This was built using: After Effects',
    imgURL: dnc,
    webURL: '',
    videoURL: dncVid
  },
  {
    order: 22,
    title: 'Cyberpunk',
    type: 'Full Page Takeovers',
    summary: 'This was the first theme launched on IGN.com. Working with product and engineering, I helped productize this for our clients and templatize this for my team.',
    techUsed: 'This was built using: React, HTML, CSS, JavaScript',
    imgURL: cyberpunk,
    webURL: '',
    videoURL: cyberpunkVid
  },
  {
    order: 22,
    title: 'Infamous',
    type: 'Rich Media Ads',
    summary: 'Taking inspiration from the game, I created this ad where the main character flys out of the site and back down',
    techUsed: 'This was built using: Flash, After Effects',
    imgURL: infamous,
    webURL: '',
    videoURL: infamousVid
  },
  {
    order: 23,
    title: 'IGN Store Ads',
    type: 'Animated Banner Ads',
    summary: 'I created a carousel ad product to showcase the new IGN store that launched',
    techUsed: 'This was built using: HTML, CSS, JavaScript ',
    imgURL: ignStore,
    webURL: '',
    videoURL: ignStoreVid
  },
  {
    order: 24,
    title: 'IGN Demo Tool',
    type: 'Web Applications',
    summary: 'I designed and developed this tool to help our Sales Team generate preview pages of previously ran ad campaigns',
    techUsed: 'This was built using: , HTML, CSS, JavaScript, PHP',
    imgURL: demoTool,
    webURL: 'https://designingdata.dev/IGN/demo-tool/main.html',
    videoURL: '',

  },
  {
    order: 25,
    title: 'Rock Band Rivals',
    type: 'Animated Banner Ads',
    summary: 'Given static assets, I was asked to create a set of animations for this game.',
    techUsed: 'This was built using: Photoshop, Adobe Animate',
    imgURL: rockBandRivals,
    webURL: 'https://designingdata.dev/IGN/animated-banner-ads/rock-band-rivals/',
    videoURL: '',
  },
  {
    order: 26,
    title: 'Best Buy - Xperia Phone',
    type: 'Animated Banner Ads',
    summary: 'Given static assets, I was asked to create a set of animations for this phone.',
    techUsed: 'This was built using: Photoshop, Adobe Animate',
    imgURL: bestBuy,
    webURL: 'https://designingdata.dev/IGN/animated-banner-ads/best-buy/xperia/',
    videoURL: '',
  
  },
  {
    order: 27,
    title: 'IMDb - Premium Billboard',
    type: 'Other',
    summary: 'Using images generated from Midjourney, I created this fake responsive ad.',
    techUsed: 'This was built using: Midjourney, HTML, CSS, JavaScript, Photoshop',
    imgURL: bloodsport,
    webURL: 'https://designingdata.dev/prototypes/bloodsport-imdb/',
    videoURL: '',
    ribbon: 'Ai Generated'
  },
  {
    order: 28,
    title: 'Whole Foods Weekly Specials ',
    type: 'Animated Banner Ads',
    summary: 'I created this project by downloading assets and entering information into a .csv.  Then I converted it into a JSON file and it is all being dynamically loaded into the ad.',
    techUsed: 'This was built using: HTML, CSS, JavaScript, Figma, Photoshop',
    imgURL: wholefoods,
    webURL: 'https://designingdata.dev/prototypes/business_insider',
    videoURL: '',
  },


  // Add other gallery items here with unique keys representing the order
  // ...
];



export default galleryItems;