import React, { useEffect } from 'react';
import './logo.scss';

const Logo = () => {
  useEffect(() => {
    // Dynamically import the dotLottie player script
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs';
    script.type = 'module';
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="logo-container">
      <dotlottie-player
        src="https://lottie.host/e02f8b7f-434b-436b-9343-23609c4eb081/1R8lBX1L1X.json"
        background="transparent"
        speed="0.5"
        autoplay
      ></dotlottie-player>
    </div>
  );
};

export default Logo;
