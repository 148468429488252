// GalleryNav.js
import React from "react";
import "./galleryNav.scss";

const WPGalleryNav = ({ selectedType, onFilter }) => {
  const itemTypes = [
    "Wordpress",
    "Shopify",
    "HTML5",
    "React",
  ];

  return (
    <div className="gallery-nav">
      <button
        className={`flat-button2 ${selectedType === "all" ? "active" : ""}`}
        onClick={() => onFilter("all")}
      >
        ALL
      </button>
      {itemTypes.map((tags) => (
        <button
          key={tags}
          className={`flat-button2 caps ${selectedType === tags ? "active" : ""}`}
          onClick={() => onFilter(tags)}
        >
          {tags}
        </button>
      ))}
    </div>
  );
  
  
};

export default WPGalleryNav;
