import React, { useState, useEffect } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import "./index.scss";
import GalleryNav from './GalleryNav';
import GalleryItem from './GalleryItem';
import galleryItems from './galleryData';

const Portfolio = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [info, setInfo] = useState(false);
  const [selectedType, setSelectedType] = useState("all");
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    // Simulating an asynchronous data fetch or any heavy operation
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust the duration as needed
  }, []);

  const toggleInfo = () => {
    const elements = document.querySelectorAll('.content-overlay');
    elements.forEach((el) => {
      el.style.visibility = info ? 'hidden' : 'visible';
    });
    setInfo(prevState => !prevState);
  };

  const handleFilter = (type) => {
    setSelectedType(type);
  };

  const sortedItems = galleryItems.sort((a, b) => a.order - b.order);

  const filteredItems = selectedType === "all" ? sortedItems : sortedItems.filter(item => item.type === selectedType);

  return (
    <>
      {loading ? (
        <Loader type="pacman" />
      ) : (
        <div className="container portfolio-page">
          <div className='header'>
            <h1 className="page-title">
              <AnimatedLetters
                letterClass={letterClass}
                strArray={"My Portfolio".split("")}
                idx={16}
              />
            </h1>
            <p className=''>
            I have a successful track record of designing and developing Rich Media Ads for clients in the gaming, consumer, and entertainment industries. My passion lies in collaborating with clients to enhance their creative vision by adding interactivity. With a strong focus on maintaining the publisher's integrity and prioritizing a positive user experience, I aim to create seamless and enjoyable ad experiences for all parties involved.</p><br />
            
            <span style={{display:'inline-block', color: '#ffd700',    fontSize: '1.5em', fontWeight: 'bold'}}>NOTE:</span><span style={{color: '#fff', fontSize: '1.3em'}}> Some of the live links have broken due to the publisher changing the website or the ad tag is no longer working.  I have created a <b>video capture</b> of those experiences.  Click each item to view the video or to open up a live link.<br /><br />
            </span>
            <div className="subNav">
              <GalleryNav selectedType={selectedType} onFilter={handleFilter} />
              <div className="toggleSwitch">
                <a className="flat-button" onClick={toggleInfo}>
                </a>
              </div>
            </div>
          </div>
          <div className="grid-container">
            {filteredItems.map(item => (
              <GalleryItem 
                key={item.title}
                title={item.title}
                type={item.type}
                summary={item.summary}
                techUsed={item.techUsed}
                imgURL={item.imgURL} 
                webURL={item.webURL}
                videoURL={item.videoURL}
                ribbon={item.ribbon}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Portfolio;
