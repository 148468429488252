// GalleryNav.js
import React from "react";
import "./galleryNav.scss";

const GalleryNav = ({ selectedType, onFilter }) => {
  const itemTypes = [
    "Rich Media Ads",
    "Full Page Takeovers",
    "Animated Banner Ads",
    "Web Applications",
    "Other"
    // Add other item types here
  ];

  return (
    <div className="gallery-nav">
      
      <button
        className={`flat-button2 ${selectedType === "all" ? "active" : ""}`}
        onClick={() => onFilter("all")}
      >
        ALL
      </button>
      {itemTypes.map((type) => (
        <button
          key={type}
          className={`flat-button2 caps ${selectedType === type ? "active" : ""}`}
          onClick={() => onFilter(type)}
        >
          {type}
        </button>
      ))}
    </div>
  );
  
  
};

export default GalleryNav;
