import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
// import About from './components/About';
// import Contact from './components/Contact';
import Layout from './components/Layout';
import Portfolio from './components/Portfolio';
import ErrorPage from './components/ErrorPage';
import Websites from './components/Websites';
import './App.scss';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="about" element={<About />} /> */}
          {/* <Route path="contact" element={<Contact />} /> */}
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="websites" element={<Websites />} />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
