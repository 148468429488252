import './sidebar.scss'
import { useState } from 'react'
// import LogoS from '../../assets/images/logo-s.png'
import logo from '../../assets/images/logo.svg'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedin,
  faGithub,
  faYoutube,
  faSkype,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons'
import {
  faHome,
  faUser,
  faEnvelope,
  faSuitcase,
  faBars,
  faClose,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'
import { Link, NavLink } from 'react-router-dom'



const Sidebar = () => {
  const [showNav, setShowNav] = useState(false);


  return (
    <div className="nav-bar">
      {/* <Link 
        className="logo"
        to="/"
        onClick={() => setShowNav(false)}>
        <img src={logo} alt="Logo" />
        <img className="sub-logo" src={LogoSubtitle} alt="Designing Data" />
      </Link> */}

      <nav className={showNav ? 'mobile-show' : ''}>
        <NavLink 
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="portfolio-link"
          to="/portfolio"
          onClick={() => setShowNav(false)}
        >
          <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e" />
        </NavLink>

        <NavLink
          activeclassname="active"
          className="wordpress-link"
          to="/websites"
          onClick={() => setShowNav(false)}
        >
          <FontAwesomeIcon icon={faWordpress} color="#4d4d4e" />
        </NavLink>

        <a
            href="https://www.linkedin.com/in/george-aguila/"
            target="_blank"
            rel="noreferrer"
            className='li-link'
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              color="#4d4d4e"
              className="anchor-icon"
            />
          </a>

        {/* <NavLink 
          activeclassname="active"
          className="about-link"
          to="/about"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink> */}

        <FontAwesomeIcon 
          onClick={() => setShowNav(false)}
          icon={faClose}
          color="#ffd700"
          size="3x"
          className='close-icon' />
      </nav>

      <FontAwesomeIcon 
          onClick={() => setShowNav(true)}
          icon={faBars}
          color="#ffd700"
          size="3x"
          className='hamburger-icon' />
    </div>
  )
}

export default Sidebar
