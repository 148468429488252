import React, { useState, useEffect } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import GalleryNav from './GalleryNav';
import GalleryItem from './GalleryItem'
import galleryItems from './WP-galleryData';
import './WPSites.scss';



const WPSites = () => {
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const [letterClass, setLetterClass] = useState('text-animate');
  const [selectedType, setSelectedType] = useState("all");
  const [info, setInfo] = useState(false);

  useEffect(() => {
    // Simulate loading delay
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after delay
    }, 2000); // Adjust the delay time as needed
  }, []);

  const toggleInfo = () => {
    const elements = document.querySelectorAll('.content-overlay');
    elements.forEach((el) => {
      el.style.visibility = info ? 'hidden' : 'visible';
    });
    setInfo(prevState => !prevState);
  };

  const handleFilter = (tags) => {
    setSelectedType(tags);
  };

  // const sortedItems = galleryItems.sort((a, b) => a.order - b.order);

  // const filteredItems = selectedType === "all" ? sortedItems : sortedItems.filter(item => item.tags === selectedType);

  const filteredItems = selectedType === "all" ? galleryItems : galleryItems.filter(item => item.tags === selectedType);


  return (
    <>
    
  {isLoading ? (
    <Loader type="pacman" />
  ) : (
    <div className="container portfolio-page">
      <div className='header'>
        <h1 className="page-title">
          <AnimatedLetters
            letterClass={letterClass}
            strArray={"Websites".split("")}
            idx={16}
          />
        </h1>
        <p className='siteDesc'>These are the websites I've designed and developed over the years.  I have experience working in Wordpress, Squarespace, and Shopify.  Click each thumbnail to open a new tab in your browser.</p>

        <div className="subNav">
          <GalleryNav selectedType={selectedType} onFilter={handleFilter} />
          <div className="toggleSwitch">
            <a className="flat-button" onClick={toggleInfo}>
            </a>
          </div>
        </div>
      </div>


      <div className="grid-container">
            {filteredItems.map(item => (
              <GalleryItem 
                key={item.title}
                title={item.title}
                type={item.type}
                summary={item.summary}
                techUsed={item.techUsed}
                imgURL={item.imgURL} 
                webURL={item.webURL}
                videoURL={item.videoURL}
                ribbon={item.ribbon}
              />
            ))}
          </div>
    </div>
    )}
    </>
  );
}

export default WPSites;
