import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { FaDesktop, FaPlayCircle } from 'react-icons/fa';
import './galleryItem.scss'; // Assuming you have a separate SCSS file for galleryItem styles
// import './VideoModal.scss'; // Assuming you have a separate SCSS file for VideoModal styles

function GalleryItem(props) {
  const [isOpen, setOpen] = useState(false);
  let videoURL = props.videoURL;

  function handleThumbClick() {
    // console.log('clicked');
    if (props.webURL !== '') {
      window.open(props.webURL);
    } else if (props.videoURL !== '') {
      setOpen(true);
    }
  }

  return (
    <div className="item-container btn btn-border-pop">
{props.ribbon && (
  <div className="ribbon-2">{props.ribbon}</div>
)}


      <div className="content-overlay">
        <div className="content-details">
          <h2>{props.title}</h2>
          <p className="summary">{props.summary}</p>
          <p className="techTxt">{props.techUsed}</p>
        </div>
      </div>

      <div className="img-container">
      {/* <div class="ribbon-1 left">Rotated Ribbon</div> */}

      
        <img className="item-thumb" src={props.imgURL} alt="" onClick={handleThumbClick} />
      </div>

      <div className="description-container">

        <div className="desc-title">
          <span className="lrg-text">{props.title}</span>
          <br />
          <span className="desc-type-text">({props.type})</span>
        </div>
        <div className="linkIcons">
          {props.webURL !== '' ? (
            <a href={props.webURL} alt="xxx" target="_blank" rel="noreferrer">
              <FaDesktop />
            </a>
          ) : null}
          {props.videoURL !== '' ? (
            <div className="viewItem" onClick={() => setOpen(true)}>
              <FaPlayCircle className="iconBtn" />
            </div>
          ) : null}
        </div>
      </div>

      {/* Render the modal separately outside of the .item-container */}
      <ModalVideo
			  customClassName="custom-modal-video" // Add the custom class here
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={videoURL}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}

export default GalleryItem;
