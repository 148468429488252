import sog from '../../assets/images/portfolio/screens/website-sog.jpg'
import brady from '../../assets/images/portfolio/wordpress_thumbs/brady.jpg'
import hellodog from '../../assets/images/portfolio/wordpress_thumbs/hellodog.jpg'
import tenkicks from '../../assets/images/portfolio/wordpress_thumbs/tenkicks.jpg'
import remi from '../../assets/images/portfolio/wordpress_thumbs/remi.jpg'
import sunflowers from '../../assets/images/portfolio/wordpress_thumbs/sunflowers.jpg'
import vida from '../../assets/images/portfolio/wordpress_thumbs/vida.jpg'
import fishon from '../../assets/images/portfolio/wordpress_thumbs/fishon.jpg'
import helloHapi from '../../assets/images/portfolio/wordpress_thumbs/hello_hapi.jpg'
import thetown from '../../assets/images/portfolio/wordpress_thumbs/the-town.jpg'
import phoenix from '../../assets/images/portfolio/wordpress_thumbs/phoenix.jpg'
import petsitting from '../../assets/images/portfolio/wordpress_thumbs/petsitting.jpg'


// ... (import images and videos)

const galleryItems = [
  {
    order: 1,
    title:'HELLO HAPI',
    type:'Website for Startup',
    tags:'Wordpress',
    summary:'This is a website that I created from the ground up.  The recipes section injests the SideChef API and the pages are created dynamically.',
    techUsed:'Wordpress, PHP, CSS',
    imgURL: helloHapi,
    webURL:'https://dev.hellohapi.com/recipes/',
    videoURL:'',
    ribbon:'In Development'
  },
  {
    order: 1,
    title:'Phoenix Feeds and Nutrition',
    type:'Redesign',
    tags:'Wordpress',
    summary:'I contributed to this project by designing some pages and building others.',
    techUsed:'Wordpress, PHP, CSS',
    imgURL: phoenix,
    webURL:'https://phoenixfeeddev.wpenginepowered.com/',
    videoURL:'',
    ribbon:'In Development'
  },
  {
    order: 2,
    title:'Summer of Gaming',
    type:'Event Recap Website',
    tags:'HTML5',
    summary:'Instead of a typical powerpoint deck, I created this to be shared internally and for clients',
    techUsed:'HTML, CSS, JavaScript, Photoshop',
    imgURL: sog,
    webURL:'https://designingdata.dev/IGN/sog/',
    videoURL:'',
    ribbon:''
  },
  {
    order: 3,
    title:'Fish-On Custom',
    type:'E-Commerce Company based in LA',
    tags:'Shopify',
    summary:'I built this site from scratch using Shopify',
    techUsed:'HTML, CSS, JavaScript, Photoshop',
    imgURL: fishon,
    webURL:'https://www.fishoncustoms.com/',
    videoURL:''
  },
  {
    order: 4,
    title:'Brady Physical Therapy',
    type:'San Mateo, CA Facility',
    tags:'Wordpress',
    summary:'Perhaps my all-time favorite custom ad...',
    techUsed:'This was created using: HTML, CSS, JavaScript, Photoshop, After Effects, Google Ad Manager',
    imgURL: brady,
    webURL:'https://archive.sonicwebsolutions.com/bpt/',
    videoURL:''
  },
  {
    order: 5,
    title:'Oh Hello Dog',
    type:'Doggy Daycare in Los Angeles',
    tags:'Wordpress',
    summary:'I designed and developed this site from the ground up',
    techUsed:'HTML, CSS, JavaScript, PHP, Photoshop',
    imgURL :hellodog,
    webURL:'https://ohhellodog.com/main/',
    videoURL:''
  },
  {
    order: 6,
    title:'Ten Kicks',
    type:'Seattle based Gym',
    tags:'Wordpress',
    summary:'I designed and developed this site from the ground up',
    techUsed:'HTML, CSS, JavaScript, PHP, Photoshop',
    imgURL: tenkicks,
    webURL:'https://tenkicks.com/',
    videoURL:''
  },
  {
    order: 7,
    title:'Remi Beverly Hills',
    type:'Image Consultant in Beverly Hills',
    tags:'Wordpress',
    summary:'I designed and developed this site from the ground up',
    techUsed:'HTML, CSS, JavaScript, PHP, Photoshop',
    imgURL: remi,
    webURL:'https://remibeverlyhills.com/',
    videoURL:''
  },
  {
    order: 8,
    title:'Sunflowers for Sallie',
    type:'Non-profit based in Maryland',
    tags:'Wordpress',
    summary:'I designed and developed this site from the ground up',
    techUsed:'HTML, CSS, JavaScript, PHP, Photoshop',
    imgURL:sunflowers,
    webURL:'https://sunflowersforsallie.org/',
    videoURL:''
  },
  {
    title:'Vida Financial',
    type:'Financial Services based in Australia',
    tags:'Wordpress',
    summary:'I designed and developed this site from the ground up',
    techUsed:'HTML, CSS, JavaScript, PHP, Photoshop',
    imgURL:vida,
    webURL:'https://vidafinancial.com.au/',
    videoURL:''
  },
  {
    title:'The Town',
    type:'Startup',
    tags:'React',
    summary:'I designed and developed this site from the ground up',
    techUsed:'Figma, HTML, CSS, JavaScript, React',
    imgURL: thetown,
    webURL:'https://thetown.rentals/',
    videoURL:''
  },
  {
    title:'The Petsitting Whisperer',
    type:'Small Business in Austraila',
    tags:'Wordpress',
    summary:'I designed and developed this site from the ground up and used Midjourney to create some of the images',
    techUsed:'HTML, CSS, JavaScript, PHP, Photoshop',
    imgURL: petsitting,
    webURL:'https://thepetsittingwhisperer.com/',
    videoURL:''
  },
];



export default galleryItems;